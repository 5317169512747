<template>
  <div class="footer-box my-footer">
    <v-footer class="footer">
      <div class="container">
        <p class="text-center">
          Copyright ©
          <a href="https://stafftimerapp.com/" target="_blank"
            >Staff&nbsp;Timer&nbsp;Ltd</a
          >
          &nbsp;{{ new Date().getFullYear() }}
        </p>
      </div>
    </v-footer>
  </div>
</template>
<script>
export default {
  name: "Footer",
};
</script>
<style scoped>
footer {
  width: 100%;
  font-size: 15px;
  z-index: 1;
  text-align: -moz-center;
  padding-right: 40px;
  margin-bottom: 0 !important;
  text-align: -webkit-center;
}
.my-footer p {
  max-height: 1px !important;
  height: 11px;
  width: 200px;
  color: gray !important;
  font-family: "Poppins", "sans-serif";
  font-size: 12px;
  letter-spacing: 0.1px;
  line-height: 12px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #5680B5 !important;
}
.my-footer a {
  color: #5680B5 !important;
  text-decoration: underline;
}

.my-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fafafa;
  z-index: 1;
}
@media (max-width: 767px) {
  footer {
    padding-right: 0px;
  }
}
</style>
